async function getEC2connection(hostName: string, startCommand: string, privateKey: string) {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/connection/ec2connection`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjMwMjUxYWIxYTJmYzFkMzllNDMwMWNhYjc1OTZkNDQ5ZDgwNDI1ZjYiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vbWlzc3lvdS1hcHAiLCJhdWQiOiJtaXNzeW91LWFwcCIsImF1dGhfdGltZSI6MTYyMjYzNjU5MiwidXNlcl9pZCI6IjFpUEx1UTVmM21iSlFHZk5wajI3aVhzVFpmbDEiLCJzdWIiOiIxaVBMdVE1ZjNtYkpRR2ZOcGoyN2lYc1RaZmwxIiwiaWF0IjoxNjIyNjM2NTkyLCJleHAiOjE2MjI2NDAxOTIsInBob25lX251bWJlciI6Iis5Nzc5ODQzNjc5MDUwIiwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJwaG9uZSI6WyIrOTc3OTg0MzY3OTA1MCJdfSwic2lnbl9pbl9wcm92aWRlciI6InBob25lIn19.VU6IUg_Kw8gRr9ieZNZ-2ig2UJJbIRTeRM3xx9IyRH3Mx93rwGuECcCfFtGah1_A81QFBNN4eXW4u8dthsYinBsKSeuiPR0ngoWlti1kaqRwVroRtwSbjW0iQy4diF-8uQkMwWQUQZFm4mNwAg3nb28NrWXVM-0z_Hn9KkNiOz8H-uuDtw_7hHBLmdZKOXcf5mhsShJ-YrdrQYhTgzqLeeJND4kPIiskdYimRR_fHIyNR4dQyjQD854nZmBR6E5S4snj00gOFeLB1FCIplvUcptqCesBBhL7J336VVYoCKSf3TKX0K9c86SPKOqzF-H9pJiBjuspOgWvPO059CEL0A',
      },
      body: JSON.stringify({
        hostName: hostName,
        privateKey: privateKey,
        command: startCommand,
      }),
    });

    console.log('this is the response: ', response);
    if (!response.ok) {
      console.log('this was executed baby');
      const errorData = await response.json();
      throw new Error(errorData.stdout);
    }

    const responseData = await response.json();
    return responseData; // Return response data including stdout
  } catch (error) {
    console.log('fetching error in error ' + error);
    throw error;
  }
}

export default getEC2connection;
