import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@ohif/ui';


const Pipelines = ({ title,children }) => {
  const [areChildrenVisible, setChildrenVisible] = useState(false);
  const handleHeaderClick = () => {
    setChildrenVisible(!areChildrenVisible);
  };

  return (
    <>
       <div
        className="bg-secondary-dark flex h-7 cursor-pointer select-none items-center justify-between rounded-[2px] pl-2.5 text-[13px]"
        onClick={handleHeaderClick}
      >
       <div className="text-white font-bold">{title}</div>
       <div className="flex items-center space-x-1">
       <div className="grid h-[28px] w-[28px] place-items-center">
            <Icon name={areChildrenVisible ? 'chevron-down-new' : 'chevron-left-new'} />
          </div>
        </div>
      </div>
      {areChildrenVisible && (
        <>
          <div className=" bg-black"></div>
          <div className="bg-primary-dark rounded-b-[4px]">{children}</div>
        </>
      )}
    </>
  );
};

Pipelines.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default Pipelines;
