import { useState, useEffect, useRef } from 'react';
import Keycloak from 'keycloak-js';
const useAuth = () => {
  const isRun = useRef(false);
  const [isLogin, setLogin] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [keycloakValue, setKeycloakValue] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    const setKeycloak = async () => {
      console.log('keycloak intializing');
      const keycloak = new Keycloak({
        url: process.env.REACT_APP_KEYCLOAK_URL,
        realm: process.env.REACT_APP_KEYCLOAK_REALM,
        clientId: process.env.REACT_APP_KEYCLOAK_CLIENT,
      });
      try {
        await keycloak.init({
          onLoad: 'login-required',
          checkLoginIframe: false,
        });
        console.log('keycloak intialized');
        console.log('keycloak testing: ', keycloak);
        setKeycloakValue(keycloak);
        setAuthenticated(true); // Set authenticated to true after successful initialization
        setLogin(true);
        setIsAdmin(
          keycloak.resourceAccess === undefined ||
            keycloak.resourceAccess['aidicom-admin']?.roles[0] === 'admin'
        );
      } catch (error) {
        console.error('Keycloak initialization failed:', error);
        setAuthenticated(false);
        setLogin(false);
      }
    };
    setKeycloak();
  }, []);
  const logout = () => {
    keycloakValue.logout({
      redirectUri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URL,
    });
  };
  return { isLogin, logout, keycloakValue, authenticated, isAdmin };
};
export default useAuth;