import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PanelSection } from '../../components';
import SegmentationConfig from './SegmentationConfig';
import SegmentationDropDownRow from './SegmentationDropDownRow';
import NoSegmentationRow from './NoSegmentationRow';
import AddSegmentRow from './AddSegmentRow';
import SegmentationGroupSegment from './SegmentationGroupSegment';
import { useTranslation } from 'react-i18next';
import { Button } from '@ohif/ui';
import getEC2connection from '../../utils/getEC2connection';
import getEC2disconnection from '../../utils/getEC2disconnection';
import Pipelines from '../Pipeline';
import pipelinejson from '../../utils/pipeline.json';
import { useLocation } from 'react-router';
import { useAuthContext } from '../../../../../platform/app/src/utils/authContext';

const SegmentationGroupTable = ({
  servicesManager,
  segmentations,
  // segmentation initial config
  segmentationConfig,
  // UI show/hide
  disableEditing,
  showAddSegmentation,
  showAddSegment,
  showDeleteSegment,
  // segmentation/segment handlers
  onSegmentationAdd,
  onSegmentationEdit,
  onSegmentationClick,
  onSegmentationDelete,
  onSegmentationDownload,
  onSegmentationDownloadRTSS,
  storeSegmentation,
  // segment handlers
  onSegmentClick,
  onSegmentAdd,
  onSegmentDelete,
  onSegmentEdit,
  onToggleSegmentationVisibility,
  onToggleSegmentVisibility,
  onToggleSegmentLock,
  onSegmentColorClick,
  // segmentation config handlers
  setFillAlpha,
  setFillAlphaInactive,
  setOutlineWidthActive,
  setOutlineOpacityActive,
  setRenderFill,
  setRenderInactiveSegmentations,
  setRenderOutline,
}) => {
  const [isConfigOpen, setIsConfigOpen] = useState(false);
  const [activeSegmentationId, setActiveSegmentationId] = useState(null);
  const [pipelineName, setPipelineName] = useState(null);

  const location = useLocation();
  const { keycloakValue } = useAuthContext();
  const searchParams = new URLSearchParams(location.search);
  const StudyInstanceUID = searchParams.get('StudyInstanceUIDs');
  const { uiNotificationService } = servicesManager.services;

  const onActiveSegmentationChange = segmentationId => {
    onSegmentationClick(segmentationId);
    setActiveSegmentationId(segmentationId);
  };

  useEffect(() => {
    // find the first active segmentation to set
    let activeSegmentationIdToSet = segmentations?.find(segmentation => segmentation.isActive)?.id;

    // If there is no active segmentation, set the first one to be active
    if (!activeSegmentationIdToSet && segmentations?.length > 0) {
      activeSegmentationIdToSet = segmentations[0].id;
    }

    // If there is no segmentation, set the active segmentation to null
    if (segmentations?.length === 0) {
      activeSegmentationIdToSet = null;
    }

    setActiveSegmentationId(activeSegmentationIdToSet);
  }, [segmentations]);

  const activeSegmentation = segmentations?.find(
    segmentation => segmentation.id === activeSegmentationId
  );
  const { t } = useTranslation('SegmentationTable');

  let patientId = null;
  let seriesData = null;
  let personnelId = null;
  const email = keycloakValue.tokenParsed.email;
  const role = keycloakValue.tokenParsed['resource_access']['aidicom-client']?.roles[0];
  async function getPatientId(resolvedStudyInstanceUID) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/scan/getParticularScan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization:
            'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjMwMjUxYWIxYTJmYzFkMzllNDMwMWNhYjc1OTZkNDQ5ZDgwNDI1ZjYiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vbWlzc3lvdS1hcHAiLCJhdWQiOiJtaXNzeW91LWFwcCIsImF1dGhfdGltZSI6MTYyMjYzNjU5MiwidXNlcl9pZCI6IjFpUEx1UTVmM21iSlFHZk5wajI3aVhzVFpmbDEiLCJzdWIiOiIxaVBMdVE1ZjNtYkpRR2ZOcGoyN2lYc1RaZmwxIiwiaWF0IjoxNjIyNjM2NTkyLCJleHAiOjE2MjI2NDAxOTIsInBob25lX251bWJlciI6Iis5Nzc5ODQzNjc5MDUwIiwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJwaG9uZSI6WyIrOTc3OTg0MzY3OTA1MCJdfSwic2lnbl9pbl9wcm92aWRlciI6InBob25lIn19.VU6IUg_Kw8gRr9ieZNZ-2ig2UJJbIRTeRM3xx9IyRH3Mx93rwGuECcCfFtGah1_A81QFBNN4eXW4u8dthsYinBsKSeuiPR0ngoWlti1kaqRwVroRtwSbjW0iQy4diF-8uQkMwWQUQZFm4mNwAg3nb28NrWXVM-0z_Hn9KkNiOz8H-uuDtw_7hHBLmdZKOXcf5mhsShJ-YrdrQYhTgzqLeeJND4kPIiskdYimRR_fHIyNR4dQyjQD854nZmBR6E5S4snj00gOFeLB1FCIplvUcptqCesBBhL7J336VVYoCKSf3TKX0K9c86SPKOqzF-H9pJiBjuspOgWvPO059CEL0A',
        },
        body: JSON.stringify({
          scanId: resolvedStudyInstanceUID,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const jsonData = await response.json();
      patientId = jsonData.data.patientId;
    } catch (error) {
      console.error('Error in getPatientID:', error);
      throw error; // Re-throw the error to be caught later
    }
  }
  async function getAllSeries(StudyInstanceUID) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/series/get-all-series`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization:
            'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjMwMjUxYWIxYTJmYzFkMzllNDMwMWNhYjc1OTZkNDQ5ZDgwNDI1ZjYiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vbWlzc3lvdS1hcHAiLCJhdWQiOiJtaXNzeW91LWFwcCIsImF1dGhfdGltZSI6MTYyMjYzNjU5MiwidXNlcl9pZCI6IjFpUEx1UTVmM21iSlFHZk5wajI3aVhzVFpmbDEiLCJzdWIiOiIxaVBMdVE1ZjNtYkpRR2ZOcGoyN2lYc1RaZmwxIiwiaWF0IjoxNjIyNjM2NTkyLCJleHAiOjE2MjI2NDAxOTIsInBob25lX251bWJlciI6Iis5Nzc5ODQzNjc5MDUwIiwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJwaG9uZSI6WyIrOTc3OTg0MzY3OTA1MCJdfSwic2lnbl9pbl9wcm92aWRlciI6InBob25lIn19.VU6IUg_Kw8gRr9ieZNZ-2ig2UJJbIRTeRM3xx9IyRH3Mx93rwGuECcCfFtGah1_A81QFBNN4eXW4u8dthsYinBsKSeuiPR0ngoWlti1kaqRwVroRtwSbjW0iQy4diF-8uQkMwWQUQZFm4mNwAg3nb28NrWXVM-0z_Hn9KkNiOz8H-uuDtw_7hHBLmdZKOXcf5mhsShJ-YrdrQYhTgzqLeeJND4kPIiskdYimRR_fHIyNR4dQyjQD854nZmBR6E5S4snj00gOFeLB1FCIplvUcptqCesBBhL7J336VVYoCKSf3TKX0K9c86SPKOqzF-H9pJiBjuspOgWvPO059CEL0A',
        },
        body: JSON.stringify({
          studyUID: StudyInstanceUID,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const jsonData = await response.json();
      seriesData = jsonData.data;
    } catch (error) {
      console.error('Error in getPatientID:', error);
      throw error; // Re-throw the error to be caught later
    }
  }

  async function getPersonnelDetail() {
    console.log('step 4', email, role);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/user/getuserbyemail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization:
            'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjMwMjUxYWIxYTJmYzFkMzllNDMwMWNhYjc1OTZkNDQ5ZDgwNDI1ZjYiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vbWlzc3lvdS1hcHAiLCJhdWQiOiJtaXNzeW91LWFwcCIsImF1dGhfdGltZSI6MTYyMjYzNjU5MiwidXNlcl9pZCI6IjFpUEx1UTVmM21iSlFHZk5wajI3aVhzVFpmbDEiLCJzdWIiOiIxaVBMdVE1ZjNtYkpRR2ZOcGoyN2lYc1RaZmwxIiwiaWF0IjoxNjIyNjM2NTkyLCJleHAiOjE2MjI2NDAxOTIsInBob25lX251bWJlciI6Iis5Nzc5ODQzNjc5MDUwIiwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJwaG9uZSI6WyIrOTc3OTg0MzY3OTA1MCJdfSwic2lnbl9pbl9wcm92aWRlciI6InBob25lIn19.VU6IUg_Kw8gRr9ieZNZ-2ig2UJJbIRTeRM3xx9IyRH3Mx93rwGuECcCfFtGah1_A81QFBNN4eXW4u8dthsYinBsKSeuiPR0ngoWlti1kaqRwVroRtwSbjW0iQy4diF-8uQkMwWQUQZFm4mNwAg3nb28NrWXVM-0z_Hn9KkNiOz8H-uuDtw_7hHBLmdZKOXcf5mhsShJ-YrdrQYhTgzqLeeJND4kPIiskdYimRR_fHIyNR4dQyjQD854nZmBR6E5S4snj00gOFeLB1FCIplvUcptqCesBBhL7J336VVYoCKSf3TKX0K9c86SPKOqzF-H9pJiBjuspOgWvPO059CEL0A',
        },
        body: JSON.stringify({
          email: email,
          userRole: role,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const jsonData = await response.json();
      console.log('role data found : ', jsonData);
      if (role == 'doctor') {
        personnelId = jsonData.data.doctorId;
      } else if (role == 'researcher') {
        personnelId = jsonData.data.researcherId;
      }
    } catch (error) {
      console.error('Error in getPersonnelDetail:', error);
      throw error; // Re-throw the error to be caught later
    }
  }

  const handlePipelineRun = async (
    name: string,
    hostName: string,
    startCommand: string,
    privateKey: string
  ) => {
    // setPipelineName(name)
    try {
      setPipelineName(prevStates => ({
        ...prevStates,
        [name]: true,
      }));
      await getPatientId(StudyInstanceUID);
      await getAllSeries(StudyInstanceUID);
      await getPersonnelDetail();

      const seriesDataJSON = JSON.stringify(seriesData);
      console.log('this person triggered the pipeline: ', personnelId);
      const modifiedStartCommand = `${startCommand} ${patientId} ${StudyInstanceUID} '${seriesDataJSON}' ${name} ${personnelId}`;
      const response = await getEC2connection(hostName, modifiedStartCommand, privateKey);
      setPipelineName(prevStates => ({
        ...prevStates,
        [name]: false,
      }));
      uiNotificationService.show({
        title: 'Success',
        message: `${response.stdout}`,
        type: 'success',
      });
    } catch (err) {
      uiNotificationService.show({
        title: 'ERROR',
        message: `${err}`,
        type: 'error',
        duration: 3000,
      });
      setPipelineName(prevStates => ({
        ...prevStates,
        [name]: false,
      }));
    }
  };

  const handlePipelineStop = async (
    name: string,
    hostName: string,
    stopCommand: string,
    privateKey: string
  ) => {
    // setPipelineName(name)
    try {
      const response = await getEC2disconnection(hostName, `/home/ubuntu/new_data/${personnelId}/${stopCommand}`, privateKey);
      console.log(response, 'Disconnection response');
      setPipelineName(prevStates => ({
        ...prevStates,
        [name]: false,
      }));
    } catch (error) {
      console.log('Error Stopping Pipeline');
    }
  };
  return (
    <div className="flex min-h-0 flex-col bg-black text-[13px] font-[300]">
      <PanelSection
        title={t('Segmentation')}
        actionIcons={
          activeSegmentation && [
            {
              name: 'settings-bars',
              onClick: () => setIsConfigOpen(isOpen => !isOpen),
            },
          ]
        }
      >
        {isConfigOpen && (
          <SegmentationConfig
            setFillAlpha={setFillAlpha}
            setFillAlphaInactive={setFillAlphaInactive}
            setOutlineWidthActive={setOutlineWidthActive}
            setOutlineOpacityActive={setOutlineOpacityActive}
            setRenderFill={setRenderFill}
            setRenderInactiveSegmentations={setRenderInactiveSegmentations}
            setRenderOutline={setRenderOutline}
            segmentationConfig={segmentationConfig}
          />
        )}
        <div className="bg-primary-dark">
          {segmentations?.length === 0 ? (
            <div className="select-none rounded-[4px]">
              {showAddSegmentation && !disableEditing && (
                <NoSegmentationRow onSegmentationAdd={onSegmentationAdd} />
              )}
            </div>
          ) : (
            <div className="mt-1 select-none">
              <SegmentationDropDownRow
                segmentations={segmentations}
                disableEditing={disableEditing}
                activeSegmentation={activeSegmentation}
                onActiveSegmentationChange={onActiveSegmentationChange}
                onSegmentationDelete={onSegmentationDelete}
                onSegmentationEdit={onSegmentationEdit}
                onSegmentationDownload={onSegmentationDownload}
                onSegmentationDownloadRTSS={onSegmentationDownloadRTSS}
                storeSegmentation={storeSegmentation}
                onSegmentationAdd={onSegmentationAdd}
                onToggleSegmentationVisibility={onToggleSegmentationVisibility}
              />
              {!disableEditing && showAddSegment && (
                <AddSegmentRow onClick={() => onSegmentAdd(activeSegmentationId)} />
              )}
            </div>
          )}
        </div>
        {activeSegmentation && (
          <div className="ohif-scrollbar mt-1.5 flex min-h-0 flex-col overflow-y-hidden">
            {activeSegmentation?.segments?.map(segment => {
              if (!segment) {
                return null;
              }

              const { segmentIndex, color, label, isVisible, isLocked } = segment;
              return (
                <div
                  className="mb-[1px]"
                  key={segmentIndex}
                >
                  <SegmentationGroupSegment
                    segmentationId={activeSegmentationId}
                    segmentIndex={segmentIndex}
                    label={label}
                    color={color}
                    isActive={activeSegmentation.activeSegmentIndex === segmentIndex}
                    disableEditing={disableEditing}
                    isLocked={isLocked}
                    isVisible={isVisible}
                    onClick={onSegmentClick}
                    onEdit={onSegmentEdit}
                    onDelete={onSegmentDelete}
                    showDelete={showDeleteSegment}
                    onColor={onSegmentColorClick}
                    onToggleVisibility={onToggleSegmentVisibility}
                    onToggleLocked={onToggleSegmentLock}
                  />
                </div>
              );
            })}
          </div>
        )}
      </PanelSection>
      <Pipelines title={t('Pipeline')}>
        {pipelinejson &&
          pipelinejson.map((item, index) => (
            <div key={index}>
              <div
                className="align-center flex justify-between px-3 py-2"
                key={index}
              >
                <div className="text-textcolor-main">{item.name}</div>
                <div>
                  {pipelineName && pipelineName[item.name] ? (
                    <button
                      onClick={() =>
                        handlePipelineStop(
                          item.name,
                          item.hostName,
                          item.stopCommand,
                          item.privateKey
                        )
                      }
                      style={{
                        backgroundColor: 'transparent',
                        color: '#a3a9b2',
                        textDecoration: 'underline',
                      }}
                    >
                      Stop
                    </button>
                  ) : (
                    <button
                      onClick={() =>
                        handlePipelineRun(
                          item.name,
                          item.hostName,
                          item.startCommand,
                          item.privateKey
                        )
                      }
                      style={{
                        backgroundColor: 'transparent',
                        color: '#a3a9b2',
                        textDecoration: 'underline',
                      }}
                    >
                      Run
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
      </Pipelines>
    </div>
  );
};

SegmentationGroupTable.propTypes = {
  segmentations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      isActive: PropTypes.bool.isRequired,
      segments: PropTypes.arrayOf(
        PropTypes.shape({
          segmentIndex: PropTypes.number.isRequired,
          color: PropTypes.array.isRequired,
          label: PropTypes.string.isRequired,
          isVisible: PropTypes.bool.isRequired,
          isLocked: PropTypes.bool.isRequired,
        })
      ),
    })
  ),
  segmentationConfig: PropTypes.object.isRequired,
  disableEditing: PropTypes.bool,
  showAddSegmentation: PropTypes.bool,
  showAddSegment: PropTypes.bool,
  showDeleteSegment: PropTypes.bool,
  onSegmentationAdd: PropTypes.func.isRequired,
  onSegmentationEdit: PropTypes.func.isRequired,
  onSegmentationClick: PropTypes.func.isRequired,
  onSegmentationDelete: PropTypes.func.isRequired,
  onSegmentationDownload: PropTypes.func.isRequired,
  onSegmentationDownloadRTSS: PropTypes.func.isRequired,
  storeSegmentation: PropTypes.func.isRequired,
  onSegmentClick: PropTypes.func.isRequired,
  onSegmentAdd: PropTypes.func.isRequired,
  onSegmentDelete: PropTypes.func.isRequired,
  onSegmentEdit: PropTypes.func.isRequired,
  onToggleSegmentationVisibility: PropTypes.func.isRequired,
  onToggleSegmentVisibility: PropTypes.func.isRequired,
  onToggleSegmentLock: PropTypes.func.isRequired,
  onSegmentColorClick: PropTypes.func.isRequired,
  setFillAlpha: PropTypes.func.isRequired,
  setFillAlphaInactive: PropTypes.func.isRequired,
  setOutlineWidthActive: PropTypes.func.isRequired,
  setOutlineOpacityActive: PropTypes.func.isRequired,
  setRenderFill: PropTypes.func.isRequired,
  setRenderInactiveSegmentations: PropTypes.func.isRequired,
  setRenderOutline: PropTypes.func.isRequired,
};

SegmentationGroupTable.defaultProps = {
  segmentations: [],
  disableEditing: false,
  showAddSegmentation: true,
  showAddSegment: true,
  showDeleteSegment: true,
  onSegmentationAdd: () => {},
  onSegmentationEdit: () => {},
  onSegmentationClick: () => {},
  onSegmentationDelete: () => {},
  onSegmentationDownload: () => {},
  onSemgnetationDownloadRTSS: () => {},
  storeSegmentation: () => {},
  onSegmentClick: () => {},
  onSegmentAdd: () => {},
  onSegmentDelete: () => {},
  onSegmentEdit: () => {},
  onToggleSegmentationVisibility: () => {},
  onToggleSegmentVisibility: () => {},
  onToggleSegmentLock: () => {},
  onSegmentColorClick: () => {},
  setFillAlpha: () => {},
  setFillAlphaInactive: () => {},
  setOutlineWidthActive: () => {},
  setOutlineOpacityActive: () => {},
  setRenderFill: () => {},
  setRenderInactiveSegmentations: () => {},
  setRenderOutline: () => {},
};
export default SegmentationGroupTable;
