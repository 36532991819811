// @ts-nocheck
// Create a new context
// import { createContext, useContext } from 'react';
import { createContext, useContext } from 'react';
const AuthContext = createContext();

// Export a custom hook to use the context
export const useAuthContext = () => useContext(AuthContext);

export default AuthContext;
